// TODO Inject .env variables or make it so that this isnt hardcoded
export const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:4001"
    : "https://api.herdpin.com";

export const MAX_DAYS_TO_SHOW = 60; 
export const DAYS = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const NULL_DATE = "0001-01-01T00:00:00Z";
