import { useState } from "react";
import { validateEmail } from "../helper/email";
import { saveUser } from "../actions/user";

const Signup = ({ name, email, accountCreated, callback }) => {
  const [userName, setUserName] = useState(name);
  const [userEmail, setUserEmail] = useState(email);
  const [, setUserAccountCreated] = useState(accountCreated);

  const emptyName = userName.length > 0 ? false : true;
  const emptyEmail = userEmail.length > 0 ? false : true;

  return (
    <>
      <div className="relative m-auto w-full max-w-lg rounded-md bg-white dark:bg-grey-900 px-4 pb-4 pt-8 md:p-8">
        <div className="mb-6 flex justify-center">
          <img
            className="h-8 lg:h-10"
            src="../assets/images/logo/logo-herdpin.svg"
            alt="HerdPin"
          />
          <div className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center">
            <a
              href="#close"
              aria-label="Close"
              data-target="modal-signup"
              onClick={() => callback()}
            >
              <svg
                className="h-6 w-6 text-grey-200"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 11.9999L17.7099 7.70994C17.8982 7.52164 18.004 7.26624 18.004 6.99994C18.004 6.73364 17.8982 6.47825 17.7099 6.28994C17.5216 6.10164 17.2662 5.99585 16.9999 5.99585C16.7336 5.99585 16.4782 6.10164 16.2899 6.28994L11.9999 10.5899L7.70994 6.28994C7.52164 6.10164 7.26624 5.99585 6.99994 5.99585C6.73364 5.99585 6.47824 6.10164 6.28994 6.28994C6.10164 6.47825 5.99585 6.73364 5.99585 6.99994C5.99585 7.26624 6.10164 7.52164 6.28994 7.70994L10.5899 11.9999L6.28994 16.2899C6.19621 16.3829 6.12182 16.4935 6.07105 16.6154C6.02028 16.7372 5.99414 16.8679 5.99414 16.9999C5.99414 17.132 6.02028 17.2627 6.07105 17.3845C6.12182 17.5064 6.19621 17.617 6.28994 17.7099C6.3829 17.8037 6.4935 17.8781 6.61536 17.9288C6.73722 17.9796 6.86793 18.0057 6.99994 18.0057C7.13195 18.0057 7.26266 17.9796 7.38452 17.9288C7.50638 17.8781 7.61698 17.8037 7.70994 17.7099L11.9999 13.4099L16.2899 17.7099C16.3829 17.8037 16.4935 17.8781 16.6154 17.9288C16.7372 17.9796 16.8679 18.0057 16.9999 18.0057C17.132 18.0057 17.2627 17.9796 17.3845 17.9288C17.5064 17.8781 17.617 17.8037 17.7099 17.7099C17.8037 17.617 17.8781 17.5064 17.9288 17.3845C17.9796 17.2627 18.0057 17.132 18.0057 16.9999C18.0057 16.8679 17.9796 16.7372 17.9288 16.6154C17.8781 16.4935 17.8037 16.3829 17.7099 16.2899L13.4099 11.9999Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="mb-6 text-center">
          <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">
            Join our community
          </h3>
          <p className="text-base font-medium text-grey-400 dark:text-grey-300 md:text-lg">
            Create a free account with your email.
          </p>
        </div>
        <form action="">
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="name"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="Your name"
              aria-label="name"
              aria-invalid={
                emptyName ? null : userName.length > 0 ? false : true
              }
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
          </div>
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              placeholder="Your email"
              aria-label="email"
              aria-invalid={
                emptyEmail ? null : validateEmail(userEmail) ? false : true
              }
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
          </div>
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                saveUser({
                  name: userName,
                  email: userEmail,
                  accountCreated: true,
                }).then(() =>{
                  setUserAccountCreated(true);
                  callback();
                });                
              }}
              className="inline-block w-full rounded-lg bg-blue-500 px-4 py-2.5 text-center text-sm font-semibold text-white transition-colors hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-100 md:py-3 md:text-base"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Signup;
