import { API_URL } from "../constants";

export const saveUser = async (user) => {
  const {name, email} = user;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      Name: name,
      Email: email,
    }),
    credentials: 'include',
  };
  return fetch(`${API_URL}/v1/user`, requestOptions)
    .then((response) => {
      return response.json()
    })
    .then((user) => {
      // TODO Handle a conflict of the user email already being in the system
      localStorage.setItem("user", JSON.stringify({...user, accountCreated: true}));
      return user;
    })
    .catch((error) => {
      console.log('error', error);
    });
};
